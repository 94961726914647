import JshModule from '@core/scripts/helper/jsh-module';

const {moduleScope} = JshModule('consent/privacy-center-footer-link');

let privacyLayerFooterLink = moduleScope.querySelector('[jsh-modal-layer-link]');
if (privacyLayerFooterLink) {
    privacyLayerFooterLink.addEventListener('click', () => {
        window.hc_consent.push("show_modal");
    });
}
